import ThemeScale from "src/types/ThemeScale";

const colors = {
  primary: "#00023D",
  secondary: "#FFFFFF",
  accent: "#E11D48",
  background: "#FFFFFF",
  backgroundSecondary: "#F4F4F4",
  text: "#333333",
  border: "#CBD4C2",
  success: "#10B981",
  warning: "#F59E0B",
  error: "#EF4444",
};

const typography = {
  fontSize: "16px",
  fontFamily: '"Noto Serif JP", sans-serif',
  fontWeightNormal: 400,
  fontWeightBold: 700,
};

const breakpoints: ThemeScale = {
  xs: "320px", // スマートフォン (portrait)
  sm: "576px", // スマートフォン (landscape)
  md: "768px", // タブレット (portrait)
  lg: "992px", // タブレット (landscape) およびデスクトップ
  xl: "1200px", // デスクトップ (large)
};

const fontSize: ThemeScale = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  xxl: "1.5rem",
};

const spacing: ThemeScale = {
  xs: "0.25rem",
  sm: "0.5rem",
  md: "1rem",
  lg: "2rem",
  xl: "4rem",
};

const duration: ThemeScale = {
  xs: "0.1s",
  sm: "0.2s",
  md: "0.3s",
  lg: "0.4s",
  xl: "0.5s",
};

const border: ThemeScale = {
  xs: "1px",
  sm: "2px",
  md: "3px",
  lg: "4px",
  xl: "5px",
};

const borderRadius: ThemeScale = {
  xs: "0.25rem",
  sm: "0.5rem",
  md: "1rem",
  lg: "2rem",
  xl: "4rem",
};

export default {
  colors,
  breakpoints,
  typography,
  fontSize,
  spacing,
  duration,
  border,
  borderRadius,
};

import { GatsbyBrowser, GatsbySSR } from "gatsby";
import React from "react";
import GlobalStyle from "src/styles/globalStyles";
import theme from "src/styles/theme";
import { ThemeProvider } from "styled-components";

const wrapRootElement: GatsbyBrowser["wrapRootElement"] &
  GatsbySSR["wrapRootElement"] = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
);

export { wrapRootElement };
